import React from "react"
import { Helmet } from "react-helmet"
import "../style/style.scss"
import FullTimeDesinger from "../img/fulltimedesinger.svg"
import favicon from "../img/favicon.png"
const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Full Time Designer</title>
        <meta
          name="description"
          content="Full-Time Designer is Resource to Inspiration, Design Idea, Tutorials, Tools Tips and Tricks, Designer Jobs"
        />
        <link rel="icon" href={favicon} type="img/x-icon"></link>
      </Helmet>
      <div className="container fullHeight">
        <div className="columns">
          <div className="text-center content">
            <img src={FullTimeDesinger} alt="Full Time Designer"></img>
            <h1>We've something special for you.</h1>
            <h3>
              We can't wait for you to see it.
              <br /> Please check back soon
            </h3>
            <h3>
              Get in Touch
              <br />
              hello@fulltimedesigner.com
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}
export default IndexPage
